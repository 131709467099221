import React from 'react'

const About = () => {
    return (
        <div className='about__container'>
            <h1>Hello there !</h1>
            <p>Bonjour à toutes et à tous, ici Mordraen ! J'écris et publie des vidéos sur Youtube depuis 2010. <br></br><br>
            </br>
            D'abord à la tête d'une chaîne entièrement dédiée au gaming, celle-ci évoque depuis 2015 les sujets Pop Culture qui me passionnent, qu'il s'agisse de films, de séries ou bien de jeux vidéo dans des formats plus analytiques, critiques ou pédagogiques.
            <br></br><br></br>
            Ces derniers temps, on me connaît surtout pour mes vidéos d'analyses et de guides sur des séries de fantasy dont House of the Dragon et Le Seigneur des Anneaux : Les Anneaux de Pouvoir mais aussi de Science-Fiction comme Star Wars Mandalorian ou Ahsoka.</p>
        </div>
    )
}

export default About