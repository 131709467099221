import React from 'react'

import { Link } from 'react-router-dom'

const ErrorPage = () => {
  return (
    <section className="error-page">
      <div className="center">
      <h2>Cette page n'existe pas</h2>
        <Link to='/' className='btn primary'>Retour à l'accueil</Link>
      </div>
    </section>
  )
}

export default ErrorPage