import React from 'react'
import { Link } from 'react-router-dom'
import PostAuthor from './PostAuthor';

const REACT_APP_ASSETS_URL = "http://localhost:5000"
const REACT_BACKEND_URL = "https://mordraen-back.onrender.com/"

const PostItem = ({thumbnail, category, postID, title, description, createdAt, authorID}) => {
    const shortDescription = description.length > 200 ?description.substr(0, 200) + '...' : description;
    const postTitle = title.length > 30 ? title.substr(0, 30) + "..." : title;

    

    return (
        <article className="post">
            <div className="post__thumbnail">
                <img src={`${REACT_BACKEND_URL}/uploads/${thumbnail}`} alt={title} />
            </div>
            <div className="post__content">
                <h3>{postTitle}</h3>
                <Link to={`/posts/categories/${category}`} className='btn category'>
                    {category}
                </Link>
            </div>
            <p dangerouslySetInnerHTML={{__html: shortDescription}}/>
            <div className='post__footer'>
            <Link to={`/posts/${postID}`} className='btn secondary'>
                Lire l'article
            </Link>
            <PostAuthor authorID={authorID} createdAt={createdAt}/>
            </div>
        </article>
    )
}

export default PostItem