import React from 'react'
import { Link } from 'react-router-dom'

const Categories = () => {
    return (
        <div className='categories'>
            <h3>Catégories</h3>
            <ul className="categories__links">
                <li><Link to="/posts/categories/Pop Culture" className='btn primary'>Pop Culture</Link></li>
                <li><Link to="/posts/categories/Cinéma" className='btn primary'>Cinéma</Link></li>
                <li><Link to="/posts/categories/Séries" className='btn primary'>Séries</Link></li>
                <li><Link to="/posts/categories/Gaming" className='btn primary'>Gaming</Link></li>
                <li><Link to="/posts/categories/Divers" className='btn primary'>Divers</Link></li>
            </ul>
        </div>
    );
};

export default Categories;