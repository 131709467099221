import React, { useContext, useEffect, useState } from 'react'
import PostItem from './PostItem'
import axios from 'axios'
import Loader from './Loader';
import Categories from './Categories'


const Posts = () => {
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const REACT_APP_BASE_URL="http://localhost:5000/api"
  const REACT_BACKEND_URL = "https://mordraen-back.onrender.com/"

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(`${REACT_BACKEND_URL}/posts`);
        setPosts(response?.data)
      } catch (error) {
        console.log(error)
      }
      setIsLoading(false)
    }

    fetchPosts();
  }, [])


  if(isLoading) {
    return <Loader/>
  }

  return (
      <section className="posts">
        
          {posts.length ? <div className="container posts__container">
              {
                posts.map(({_id:id, thumbnail, category, title, description, creator, createdAt}) => 
                <PostItem 
                key={id} 
                postID={id} 
                thumbnail={thumbnail} 
                category={category} 
                title={title} 
                description={description} 
                authorID={creator} 
                createdAt={createdAt}
                />)
              }
              
          </div> : <h2 className='center'>Aucun article trouvé</h2>}
          <Categories />
      </section>
  )
}

export default Posts