import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { UserContext } from '../context/userContext';

const DeletePost = () => {
  const { id } = useParams;
  const navigate = useNavigate()

  const { currentUser } = useContext(UserContext)
  const token = currentUser?.token;

  // redirect to login page for any user to lands on this page without token
  useEffect(() => {
    if (!token) {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    const removePost = async () => {
      try {
        const response = await axios.delete(`${process.env.REACT_BACKEND_URL}/posts/${id}`)

      } catch (error) {
        console.log(error)
      }
    }

    removePost()
  }, [])


  return (
    <div></div>
  )
}

export default DeletePost