import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

const Layout = () => {
  const location = useLocation();
  const pathsWithoutFooter = ['/create', '/myposts/66993bab5458bdbed1259b6e', '/profile/66993bab5458bdbed1259b6e'];

  const showFooter = !pathsWithoutFooter.includes(location.pathname);
  return (
    <div className='layout'>
      <Header />
      <main>
        <Outlet />
      </main>
      {showFooter && <Footer />}
    </div>
  )
}

export default Layout