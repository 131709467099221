import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import logobanniere from '../images/logobanniere.png';
import { FaBars } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import {UserContext} from '../context/userContext.js'
import kofi from "../images/kofi.png"



const Header = () => {
  const {currentUser} = useContext(UserContext)
  const [isNavShowing, setIsNavShowing] = useState(window.innerWidth > 800);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 800) {
        setIsNavShowing(true);
      } else {
        setIsNavShowing(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const closeNavHandler = () => {
    if (window.innerWidth < 800) {
      setIsNavShowing(false);
    }
  };

  return (
    <nav>
      <div className="container nav__container">
        <Link to="/" className='nav__logo' onClick={closeNavHandler}>
          <img src={logobanniere} alt="Logo bannière Mordraen" />
        </Link>

        {!currentUser?.id && isNavShowing && <ul className='nav__menu'>
                <li><Link to={'/about'} onClick={closeNavHandler}>À PROPOS</Link></li>
                <li><Link to={'/socials'} onClick={closeNavHandler}>ME SUIVRE</Link></li>
                <li><Link to={'/support'} onClick={closeNavHandler}><p>ME SOUTENIR<img src={kofi}/></p></Link></li>
            </ul>}
        {currentUser?.id && isNavShowing && (
          <ul className='nav__menu'>
            <li><Link to={`/profile/${currentUser?.id}`} onClick={closeNavHandler}>{currentUser?.name}</Link></li>
            <li><Link to="/about" onClick={closeNavHandler}>À Propos</Link></li>
            <li><Link to="/create" onClick={closeNavHandler}>Créer Article</Link></li>
            <li><Link to="/logout" onClick={closeNavHandler}>Déconnexion</Link></li>
          </ul>
        )}
        <button className="nav__toggle-btn" onClick={() => setIsNavShowing(!isNavShowing)}>
          {isNavShowing ? <FaXmark /> : <FaBars />}
        </button>
      </div>
    </nav>
  );
}

export default Header;
