import React from 'react'
import facebook from '../images/facebook.png'
import youtube from '../images/youtube.png'
import twitter from '../images/twitter.png'
import twitch from '../images/twitch.png'
import tiktok from '../images/tiktok.png'
import kofi from '../images/kofi.png'

export const Socials = () => {
    return (
        <section className='socials__container'>
            <p>Retrouve-moi sur mes réseaux :</p>
            <div className='socials'>
                <a href="https://www.facebook.com/mordraen/"><img src={facebook} alt="" /></a>
                <a href="https://x.com/Mordraen"><img src={twitter} alt="" /></a>
                <a href="https://www.youtube.com/channel/UCMX52Kv_2d347sYzJyejNtw"><img src={youtube} alt="" /></a>
                <a href="https://www.twitch.tv/Mordraen"><img src={twitch} alt="" /></a>
                <a href="https://www.tiktok.com/@mordraen"><img src={tiktok} alt="" /></a>
            </div>
            <div className='kofi'>
                <p>Tu peux aussi me soutenir sur KoFi !</p>
                <a href="https://ko-fi.com/mordraen"><img src={kofi} alt="" /></a>
            </div>
        </section>
    )
}
