import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ReactTimeAgo from "react-time-ago"
import TimeAgo from 'javascript-time-ago'

import fr from 'javascript-time-ago/locale/fr.json'

TimeAgo.addDefaultLocale(fr)

const REACT_APP_BASE_URL = "http://localhost:5000/api"
const REACT_APP_ASSET_URL="http://localhost:5000"
const REACT_BACKEND_URL = "https://mordraen-back.onrender.com/"


const PostAuthor = ({authorID, createdAt}) => {
    const [author, setAuthor] = useState({})
    
    useEffect(() => {
        const getAuthor = async () => {
            try {
                const response = await axios.get(`${REACT_BACKEND_URL}/users/${authorID}`)
                setAuthor(response?.data)
            } catch (error) {
                console.log(error)
            }
        }

        getAuthor();
    }, [])


    return (
        <Link to={`/posts/users/${authorID}`} className="post__author">
            <div className="post__author-avatar">
                <img src={`${REACT_APP_ASSET_URL}/uploads/${author?.avatar}`} alt="" />
            </div>
            <div className="post__author-details">
                <h5>Par {author?.name}</h5>
                <small><ReactTimeAgo date={new Date(createdAt)} locale="fr-FR" /></small>
            </div>
        </Link>
    )
}

export default PostAuthor