import React from "react";
import logo from "../images/logobanniere.png"
import facebook from '../images/facebook.png'
import youtube from '../images/youtube.png'
import twitter from '../images/twitter.png'
import twitch from '../images/twitch.png'
import tiktok from '../images/tiktok.png'
import kofi from '../images/kofi.png'


const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <footer>
      <img 
      src={logo}
      alt="logo mordraen"
      onClick={scrollToTop}
      style={{ cursor: "pointer" }}
      />
      <div className="footer__copyright">
        <small>Copyright &copy; 2024 MORDRAEN - Made with 💜 by <a href="https://adrienverwaerde.github.io/Portfolio/" target="_blank">Adrien Verwaerde</a> </small>
      </div>
      <div className='social__links'>
                <a href="https://www.facebook.com/mordraen/"><img src={facebook} alt="" /></a>
                <a href="https://x.com/Mordraen"><img src={twitter} alt="" /></a>
                <a href="https://www.youtube.com/channel/UCMX52Kv_2d347sYzJyejNtw"><img src={youtube} alt="" /></a>
                <a href="https://www.twitch.tv/Mordraen"><img src={twitch} alt="" /></a>
                <a href="https://www.tiktok.com/@mordraen"><img src={tiktok} alt="" /></a>
                <a href="https://ko-fi.com/mordraen"><img src={kofi} alt="" /></a>
            </div>
    </footer>
  );
};

export default Footer;
