import React from 'react'
import kofi from '../images/kofi.png'

export const Support = () => {
    return (
        <section className='support__page'>
            <h1>Me soutenir sur Ko-Fi</h1>
            <p>Devenir membre sur Ko-Fi est la meilleure solution pour me soutenir !
                <br></br>
                <br></br>
                Clique sur l'icône pour découvrir les différents abonnements que je propose ❤️</p>
            <a href="https://ko-fi.com/mordraen/tiers"><img src={kofi} alt="" /></a>
        </section>
    )
}
